import { UserRole } from '@api-open';
import { RolesList } from '@shared/services';

type Permissions = { [key: string]: RolesList[] };

export const permissions: Permissions = {
  user: [
    { user: { role: UserRole.user, isLocked: false } },
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  catalogs: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner, isLocked: false } },
    { user: { role: UserRole.admin } },
  ],
  home: [
    { user: { role: UserRole.user, isLocked: false } },
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  projects: [
    { user: { role: UserRole.user, isLocked: false } },
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  projectGroups: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  applicationsHead: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  repositories: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  applications: [
    { user: { role: UserRole.manager } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  exploreApplications: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  manager: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  flavorInfo: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  images: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  auditLog: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  users: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  userGroups: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  projectQuotas: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  servers: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  taikunRequests: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  showbackRules: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  showbackSummary: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  ticketing: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  billing: [
    { user: { role: UserRole.manager, owner: true } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  chargeback: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  usageReports: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  subscription: [
    { user: { role: UserRole.manager, owner: true } },
    { user: { role: UserRole.partner, owner: true } },
    { user: { role: UserRole.admin } },
  ],
  infraDetails: [
    { user: { role: UserRole.manager, owner: true }, userGeneral: { trialDays: null } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  payments: [
    { user: { role: UserRole.manager, owner: true, isLocked: false } },
    { user: { role: UserRole.partner, owner: true, isLocked: false } },
    { user: { role: UserRole.admin } },
  ],
  credentials: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  cloudCredentials: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  backupCredentials: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  billingCredentials: [{ user: { role: UserRole.partner } }, { user: { role: UserRole.admin } }],
  showbackCredentials: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  aiCredentials: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  profiles: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  kubernetesProfiles: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  accessProfiles: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  alertingProfiles: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  policyProfiles: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  standaloneProfiles: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  partner: [{ user: { role: UserRole.partner } }, { user: { role: UserRole.admin } }],
  organizations: [{ user: { role: UserRole.partner } }, { user: { role: UserRole.admin } }],
  billingRules: [{ user: { role: UserRole.partner } }, { user: { role: UserRole.admin } }],
  admin: [{ user: { role: UserRole.admin } }],
  kubespray: [{ user: { role: UserRole.admin } }],
  partners: [{ user: { role: UserRole.admin } }],
  adminControlPanel: [{ user: { role: UserRole.admin } }],
  settings: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  slackConfiguration: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  userTokens: [
    { user: { role: UserRole.manager, isLocked: false } },
    { user: { role: UserRole.partner } },
    { user: { role: UserRole.admin } },
  ],
  informingUser: [
    { user: { role: UserRole.user, isLocked: true } },
    { user: { role: UserRole.manager, owner: false, isLocked: true } },
  ],
};
